import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import ContextProvider from "../src/Translate/ContextProvider.jsx";
import MoodProvider from "./DarkMode/ContextDarkMood";
import ContextGets from "./Context/ContextGets";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <MoodProvider>
      <ContextGets>
        <ContextProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ContextProvider>
      </ContextGets>
    </MoodProvider>
  </React.StrictMode>
);

import { ImTruck  } from "react-icons/im";
function Title() {
  return (
    <div className="flex items-center justify-center gap-3 my-7">
      <div className="border-2 border-Main w-24"/>
      <ImTruck  size={30} className="text-Main"/>
      <div className="border-2 border-Main w-24"/>
    </div>
  );
}
export default Title;

import { Swiper, SwiperSlide } from "swiper/react";
import opacity from "../../images/Rectangle 2.png";
import { Pagination } from "swiper/modules";
import { useContextApi } from "../../Context/ContextGets";
import { fileUrl, useFETCH } from "../../Tools/APIs";
import { Col } from "../../Tools/Grid-system";

function Slider() {
  const { dataAll } = useContextApi();
  const { data, isLoading } = useFETCH(`home-images?type=main_images`);
  console.log(data);
  return (
    <div className="relative">
      {isLoading ? (
        <>
          <Col>
            <div className="loadingPro rounded-md overflow-hidden w-full h-[70vh]">
              <div className="h-full w-full"></div>
            </div>
          </Col>
        </>
      ) : (
        ""
      )}
      <Swiper
        pagination={true}
        modules={[Pagination]}
        className="mySwiper h-[70vh]"
        style={{ direction: "ltr" }}
      >
        {data?.data.data.map((e) => (
          <SwiperSlide>
            <img src={fileUrl + e.image} alt="" className="w-full" />
            <img
              src={opacity}
              alt=""
              className="w-full h-[70vh] object-cover absolute top-0 left-0 transition-all"
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="absolute z-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 space-y-5 text-center text-white">
        <h2 className="font-bold text-2xl">{dataAll?.title_home}</h2>
        <p className="font-semibold text-lg">{dataAll?.description_home}</p>
      </div>
    </div>
  );
}
export default Slider;

import { Link } from "react-router-dom";
import { useContextMood } from "../../DarkMode/ContextDarkMood";
import logoDark from "../../images/Logo.png";
import logo from "../../images/lorry.jpg";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { useContextApi } from "../../Context/ContextGets";
import { BsWhatsapp } from "react-icons/bs";
import { useContextTranslate } from "../../Translate/ContextProvider";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
const Footer = () => {
  const { mood } = useContextMood();
  const { content } = useContextTranslate();
  const { dataAll } = useContextApi();
  return (
    <>
      <div
        className={`footer border-t-4 shadow-xl ${
          mood === "dark" ? "text-white" : "text-black/90"
        }`}
      >
        <Container>
          <Row className="titleNave items-center">
            <Col md={4} className="justify-center">
              <img
                src={mood === "light" ? logo : logoDark}
                className="w-56 mb-1 mx-auto"
                alt=""
              />
              <div className="flex items-center gap-2 mx-3 justify-center">
                <a
                  href={`whatsapp://send?phone=${dataAll?.whatsapp}`}
                  target="_blank"
                  className="bg-Secondary w-8 h-8 rounded-full flex justify-center items-center p-1"
                >
                  <BsWhatsapp
                    size={20}
                    color="#fff"
                    className="cursor-pointer"
                  />
                </a>
                <a
                  href={dataAll?.facebook}
                  target="_blank"
                  className="bg-Secondary w-8 h-8 rounded-full flex justify-center items-center p-1"
                >
                  <FaFacebookF
                    size={20}
                    color="#fff"
                    className="cursor-pointer"
                  />
                </a>
                <a
                  href={dataAll?.instagram}
                  target="_blank"
                  className="bg-Secondary w-8 h-8 rounded-full flex justify-center items-center p-1"
                >
                  <FaInstagram
                    size={20}
                    color="#fff"
                    className="cursor-pointer"
                  />
                </a>
                <a
                  href={`mailto:${dataAll?.email}`}
                  target="_blank"
                  className="bg-Secondary w-8 h-8 rounded-full flex justify-center items-center p-1"
                >
                  <HiOutlineMail
                    size={25}
                    color="#fff"
                    className="cursor-pointer"
                  />
                </a>
              </div>
            </Col>

            <Col md={4} className="text-center">
              <h2 className="text-xl font-bold">{dataAll?.title_footer}</h2>
              <p className="">{dataAll?.description_footer}</p>
            </Col>
            <Col md={4} className="">
              <div className="flex  items-center gap-3">
                <span className="text-lg font-bold">{content.Mobil} :</span>
                <span className="">{dataAll?.whatsapp}</span>
              </div>
              <div className="flex  items-center gap-3">
                <span className="text-lg font-bold">{content.Mail} :</span>
                <span className="">{dataAll?.email}</span>
              </div>
            </Col>
          </Row>
          <div className="flex items-center justify-around border-t-2 border-Third pt-2 max-sm:flex-col">
            <Link to="/datenschutz">
              <p className="font-bold text-lg cursor-pointer">
                {content.datenschutz}
              </p>
            </Link>
            <Link to="/impressum">
              <p className="font-bold text-lg cursor-pointer">
                {content.impressum || "IMPRESSUM"}
              </p>
            </Link>
            <div className="cursor-pointer">
              <a
                href={`whatsapp://send?phone=0968339368`}
                target="_blank"
                className="flex justify-center items-center gap-1 hover:text-Main"
              >
                <p className="cursor-pointer font-bold text-lg">
                  {content.Art}
                </p>
                <BsWhatsapp size={20} className="cursor-pointer" />
              </a>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Footer;

import React, { createContext, useContext } from "react";
import { useFETCH } from "../Tools/APIs";

const ContextAPIs = createContext({});
const ContextGets = ({ children }) => {
  const { data } = useFETCH(
    `info?local=${window.localStorage.getItem("language")}`
  );
  let dataAll = data?.data.data;
  return (
    <ContextAPIs.Provider value={{ dataAll }}>{children}</ContextAPIs.Provider>
  );
};

export default ContextGets;

export const useContextApi = () => {
  return useContext(ContextAPIs);
};

import { ContactUsComponent } from "../../components/Index";
import { Container } from "../../Tools/Grid-system";
import SectionOne from "./SectionOne";
import SectionThree from "./SectionThree";
import SectionTwo from "./SectionTwo";
const Home = () => {
  return (
    <>
      <SectionOne />
      <Container>
        <SectionTwo />
        <SectionThree />
        <ContactUsComponent />
      </Container>
    </>
  );
};

export default Home;

import React from "react";
import "./DarkMode.css";
import { useContextMood } from "./ContextDarkMood";
import { FaRegMoon, FaSun } from "react-icons/fa";

const DarkMode = () => {
  const { mood, setMood } = useContextMood();
  window.localStorage.setItem("mood", mood);
  return (
    <>
      <div
        onClick={() => {
          mood === "light" ? setMood("dark") : setMood("light");
        }}
        className={`${
          mood === "light" ? "bg-slate-200" : "bg-black/90"
        } w-[4.2rem] darkBox transition-all cursor-pointer h-8 rounded-full flex items-center justify-around relative text-black`}
      >
        <div
          className={`${
            mood === "dark"
              ? " right-1 bg-slate-400"
              : "left-1 bg-gradient-to-t from-[#ffcc89] to-[#d8860b]"
          } active:w-8  absolute w-7 h-7 transition-all bg-white rounded-full bg-opacity-50`}
        />
        <FaSun color="#ffffff" size={20} className="z-10" />
        <FaRegMoon
          color={mood === "dark" && "#fff"}
          size={20}
          className="z-10"
        />
      </div>
    </>
  );
};

export default DarkMode;

import { useFETCH } from "../../Tools/APIs";
import { Container } from "../../Tools/Grid-system";
import { Title } from "../../components/Index";

function DATENSCHUTZ() {
  const { data: dataText, isLoading: isLoadingText } = useFETCH(
    `protection?local=${localStorage.getItem("language")}`
  );
  return (
    <div>
      <Container>
        <Title />
        <h1 className="text-2xl font-serif text-center mb-5">DATENSCHUTZ</h1>
        {dataText?.data.data.map((e) => (
          <>
            <h3 className="font-bold text-2xl">{e.title}</h3>
            <p className="font-serif my-3 text-lg leading-8">{e.description}</p>
          </>
        ))}
      </Container>
    </div>
  );
}
export default DATENSCHUTZ;

import logoDark from "../../images/Logo.png";
import logo from "../../images/lorry.jpg";
import { Icon, Slider, Title } from "../../components/Index";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { FaPhoneAlt } from "react-icons/fa";
import { useContextApi } from "../../Context/ContextGets";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards } from "swiper/modules";
import { fileUrl, useFETCH } from "../../Tools/APIs";
import { useContextMood } from "../../DarkMode/ContextDarkMood";
const SectionOne = () => {
  const { dataAll } = useContextApi();
  const { mood } = useContextMood();
  const { data } = useFETCH(`home-images?type=about_images`);
  return (
    <div className="">
      <div className="flex justify-center items-center gap-5 my-3">
        <img
          src={mood === "light" ? logo : logoDark}
          alt=""
          className="w-[250px] max-sm:w-[160px]"
        />
        <div>
          {dataAll?.number_one != 0 && (
            <Icon
              className="border-2 border-Main rounded-xl py-2 px-2 sm:px-4 max-sm:text-sm hover:text-white text-Main hover:bg-Main w-full"
              title={dataAll?.number_one}
            >
              <FaPhoneAlt className="cursor-pointer w-[30px] h-[30px] max-sm:w-[20px] max-sm:h-[20px]" />
            </Icon>
          )}
          {dataAll?.number_two != 0 && (
            <Icon
              className="border-2 border-Main rounded-xl py-2 px-2 sm:px-4 max-sm:text-sm hover:text-white text-Main hover:bg-Main w-full"
              title={dataAll?.number_two}
            >
              <FaPhoneAlt className="cursor-pointer w-[30px] h-[30px] max-sm:w-[20px] max-sm:h-[20px]" />
            </Icon>
          )}
        </div>
      </div>
      <Slider />
      <Container>
        <Title />
        <Row className="items-center">
          <Col md={8}>
            <h1 className="text-xl font-bold mb-5">{dataAll?.title_about}</h1>
            <p className="leading-7">{dataAll?.description_about}</p>
          </Col>
          <Col md={4}>
            <Swiper
              effect={"cards"}
              grabCursor={true}
              modules={[EffectCards]}
              className="mySwiper"
            >
              {data?.data.data.map((e) => (
                <SwiperSlide>
                  <img
                    src={fileUrl + e.image}
                    alt=""
                    className="w-full min-h-[300px] px-2 object-contain"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SectionOne;

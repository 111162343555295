import { useParams } from "react-router-dom";
import { useFETCH } from "../../Tools/APIs";
import { Container } from "../../Tools/Grid-system";
import { Slider, Title } from "../../components/Index";

function Details() {
  const { id } = useParams();
  const { data: dataText, isLoading: isLoadingText } = useFETCH(
    `features/${id}?local=${localStorage.getItem("language")}`
  );
  return (
    <div>
      <Slider />
      <Container>
        <Title />
        <>
          <h3 className="text-2xl font-serif text-center">
            {dataText?.data.data.title}
          </h3>
          <p className="font-serif my-3 text-center text-lg leading-8">
            {dataText?.data.data.description}
          </p>
        </>
      </Container>
    </div>
  );
}
export default Details;

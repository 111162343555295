import React from "react";
import { Container } from "../../Tools/Grid-system";
import { PrintHTML, Title } from "../../components/Index";
import { useContextTranslate } from "../../Translate/ContextProvider";
import { useContextApi } from "../../Context/ContextGets";

const Impressum = () => {
  const { content } = useContextTranslate();
  const { dataAll } = useContextApi();
  console.log(dataAll?.impressum);
  return (
    <div>
      <Container>
        <Title />
        <h1 className="text-2xl font-serif text-center mb-5 min-h-[30vh]">
          {content.impressum || "IMPRESSUM"}
        </h1>
        <br />
        <PrintHTML text={dataAll?.impressum} />
      </Container>
    </div>
  );
};

export default Impressum;

import { Link } from "react-router-dom";

function Button({ link, name }) {
  return (
    <Link to={link || ""}>
      <button className="border font-semibold border-Third text-center px-8 py-2 mb-3 mt-5 text-black rounded-md hover:bg-Third hover:text-white transition-all ease-in">
        {name} 
      </button>
    </Link>
  );
}
export default Button;

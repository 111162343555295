import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination } from "swiper/modules";
import { fileUrl, useFETCH } from "../../Tools/APIs";

function SectionThree() {
  const { data, isLoading } = useFETCH(`home-images?type=home_images`);
  const { data: dataText, isLoading: isLoadingText } = useFETCH(
    `home-text?local=${localStorage.getItem("language")}`
  );
  return (
    <>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={3}
        breakpoints={{
          900: {
            slidesPerView: 3.2,
          },
          500: {
            slidesPerView: 2.3,
          },
          0: {
            slidesPerView: 1.2,
          },
        }}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={true}
        modules={[EffectCoverflow, Pagination]}
        className={`mySwiper h-[300px] my-10 ${
          window.localStorage.getItem("language") === "ar"
            ? "direction"
            : "direction"
        }`}
        initialSlide={1}
      >
        {data?.data.data.map((e) => (
          <SwiperSlide>
            <img
              src={fileUrl + e.image}
              alt=""
              className="w-full h-full object-contain"
            />
          </SwiperSlide>
        ))}
      </Swiper>
      {dataText?.data.data.map((e) => (
        <div className="mt-8">
          <h3 className="text-2xl font-serif text-center">{e.title}</h3>
          <p className="font-serif my-3 text-center text-lg leading-8">
            {e.description}
          </p>
        </div>
      ))}
    </>
  );
}
export default SectionThree;

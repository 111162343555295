import React, { createContext, useContext, useEffect, useState } from "react";

const ContextMood = createContext({});

const MoodProvider = ({ children }) => {
  const [mood, setMood] = useState(
    window.localStorage.getItem("mood") || "light"
  );
  return (
    <ContextMood.Provider value={{ setMood, mood }}>
      {children}
    </ContextMood.Provider>
  );
};

export default MoodProvider;

export const useContextMood = () => {
  return useContext(ContextMood);
};

import { Container } from "../../Tools/Grid-system";
import { ContactUsComponent, Slider, Title } from "../../components/Index";

function ContactUs() {
  return (
    <>
      <Slider />
      <Title />
      <Container className="pt-0">
        <ContactUsComponent />
      </Container>
    </>
  );
}
export default ContactUs;

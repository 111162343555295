import { Route, Routes, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { P403, P404, P500 } from "./Tools/Error";
import {
  ContactUs,
  DATENSCHUTZ,
  Details,
  Features,
  Home,
  Impressum,
} from "./pages/Index";
import { Footer, Navbar } from "./Layout/Index";
import { useContextMood } from ".//DarkMode/ContextDarkMood";

const App = () => {
  const { mood } = useContextMood();
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className={mood === "dark" ? "text-white bg-black/90" : ""}>
      <Navbar />
      <Routes>
        <Route path="403" element={<P403 />} />
        <Route path="*" element={<P404 />} />
        <Route path="500" element={<P500 />} />
        <Route path="/">
          <Route index element={<Home />} />
          <Route path="home/:id" element={<Details />} />
        </Route>
        <Route path="impressum" element={<Impressum />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="features" element={<Features />} />
        <Route path="datenschutz" element={<DATENSCHUTZ />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;

function ImageCard({ children, title, text, className }) {
  return (
    <div className="border-2 border-Main rounded-2xl overflow-hidden w-full h-full my-3 mx-auto">
      {children}
      <h2
        className={`font-bold text-2xl max-sm:text-lg  text-center text-Third my-1 px-5 ${className}`}
      >
        {title}
      </h2>
      <p className="text-lg text-center font-semibold">{text}</p>
    </div>
  );
}
export default ImageCard;

function Input({ type, name, className, placeholder, onChange }) {
  return (
      <input
        type={type || "text"}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        className={`border-2 border-Main bg-gray-100 placeholder:text-gray-600 rounded-xl py-3 px-5 ${className}`}
      />
  );
}
export default Input;

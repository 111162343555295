function TextArea({ type, name, className, placeholder, onChange }) {
  return (
    <textarea
      type={type || "text"}
      name={name}
      required
      placeholder={placeholder}
      onChange={onChange}
      className={`border-2 border-Main outline-none h-40 bg-gray-100 placeholder:text-gray-600 rounded-xl py-3 px-5 w-full ${className}`}
    />
  );
}
export default TextArea












import { MdDensityMedium } from "react-icons/md";
import { useContextTranslate } from "../../Translate/ContextProvider";
import { NavLink } from "react-router-dom";
import Translation from "../../Translate/Translation";
import DarkMode from "../../DarkMode/DarkMode";
import { useContextMood } from "../../DarkMode/ContextDarkMood";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { BsWhatsapp } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import { useClose } from "../../Tools/APIs";
import { useContextApi } from "../../Context/ContextGets";
const Navbar = () => {
  const { open, mouse, setOpen } = useClose();
  const { mood } = useContextMood();
  const { content } = useContextTranslate();
  const { dataAll } = useContextApi();
  const navbar = [
    { name: `${content.home}`, link: "/" },
    { name: `${content.Features}`, link: "/features" },
    { name: `${content.ContactUs}`, link: "/contact-us" },
  ];
  return (
    <>
      <div
        className={`sticky px-3 md:px-10 z-40 top-0 text-black left-0 w-full flex justify-between items-center shadow-sm border-b-4 py-3  ${
          mood === "dark" ? "bg-black text-white" : "bg-white"
        }`}
      >
        <Translation />
        <div ref={mouse}>
          <MdDensityMedium
            className="lg:hidden max-lg:mx-10 "
            size={25}
            onClick={() => setOpen(!open)}
          />
          <ul
            className={`justify-around  items-center flex-1 lg:gap-10 lg:flex ${
              open
                ? "flex-col w-[225px] absolute top-12 left-1/2 text-black -translate-x-1/2 border-t shadow-xl rounded-3xl text-center bg-white bg-nav py-4 px-5 space-y-7"
                : "hidden"
            }`}
          >
            {navbar.map((e) => (
              <li className={`text-lg font-semibold`}>
                <NavLink to={e.link} className="pb-1">
                  {e.name}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex items-center gap-2 mx-3 max-[500px]:hidden">
          <a
            href={`whatsapp://send?phone=${dataAll?.whatsapp}`}
            target="_blank"
            className="bg-Secondary w-8 h-8 rounded-full flex justify-center items-center p-1"
          >
            <BsWhatsapp size={20} color="#fff" className="cursor-pointer" />
          </a>
          <a
            href={dataAll?.facebook}
            target="_blank"
            className="bg-Secondary w-8 h-8 rounded-full flex justify-center items-center p-1"
          >
            <FaFacebookF size={20} color="#fff" className="cursor-pointer" />
          </a>
          <a
            href={dataAll?.instagram}
            target="_blank"
            className="bg-Secondary w-8 h-8 rounded-full flex justify-center items-center p-1"
          >
            <FaInstagram size={20} color="#fff" className="cursor-pointer" />
          </a>
          <a
            href={`mailto:${dataAll?.email}`}
            target="_blank"
            className="bg-Secondary w-8 h-8 rounded-full flex justify-center items-center p-1"
          >
            <HiOutlineMail size={25} color="#fff" className="cursor-pointer" />
          </a>
        </div>
        <DarkMode />
      </div>
    </>
  );
};

export default Navbar;

const P404 = () => {
  return (
    <div>
      <h1 className="grid place-content-center h-[80vh] text-Brown font-semibold ">
        404 | no page found
      </h1>
    </div>
  );
};

export default P404;

import { useContextTranslate } from "../../Translate/ContextProvider";
import { Button } from "../Index";

function Card({ title, text, id }) {
  const { content } = useContextTranslate();
  return (
    <div className="bg-gray-50 border-2 border-Third rounded-xl p-3 my-5 max-md:w-full mx-auto">
      <h1 className="font-bold text-2xl text-center my-3">{title}</h1>
      <p className="text-lg text-center text-gray-600">
        {text.substring(0, 250)}...
      </p>
      <div className="flex justify-center">
        <Button name={content.SeeAll} link={"/home/" + id} />
      </div>
    </div>
  );
}
export default Card;

import { usePOST } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { Input, TextArea, Icon } from "../Index";
import { useContextTranslate } from "../../Translate/ContextProvider";
import { HiOutlineMail } from "react-icons/hi";
import { FaPhoneAlt } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import { useContextApi } from "../../Context/ContextGets";
const ContactUsComponent = () => {
  const { content } = useContextTranslate();
  const { dataAll } = useContextApi();
  const { handleChangeInput, loading, handleSubmit, error } = usePOST({});
  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleSubmit(`message`);
  };
  return (
    <div>
      <h1 className="text-4xl underline decoration-Secondary font-serif text-center decoration-4 mb-5">
        {content.ContactUs}
      </h1>
      <Container>
        <Row>
          <Col md={6}>
            <Row>
              <Col md={6}>
                <Input
                  name="name"
                  placeholder={content.YourName}
                  onChange={handleChangeInput}
                />
              </Col>
              <Col md={6}>
                <Input
                  type="email"
                  name="email"
                  placeholder={content.YourEmail}
                  onChange={handleChangeInput}
                />
              </Col>
              <Col md={12}>
                <Input
                  name="location"
                  placeholder={content.HomeLocation}
                  onChange={handleChangeInput}
                />
              </Col>
              <Col md={6}>
                <Input
                  name="house_number"
                  placeholder={content.HomeNumber}
                  onChange={handleChangeInput}
                />
              </Col>
              <Col md={6}>
                <Input
                  name="street_name"
                  placeholder={content.streetName}
                  onChange={handleChangeInput}
                />
              </Col>
              <Col md={6}>
                <Input
                  name="house_area"
                  placeholder={content.homeDistance}
                  onChange={handleChangeInput}
                />
              </Col>
              <Col md={6}>
                <Input
                  name="phone_number"
                  placeholder={content.floor}
                  onChange={handleChangeInput}
                />
              </Col>
              <Col>
                <TextArea
                  type="text"
                  name="message"
                  placeholder={content.YourMessage}
                  onChange={handleChangeInput}
                />
              </Col>
              {loading ? <Loading /> : ""}
              <div>{error}</div>
              <div className="text-center" whileTap={{ scale: 0.7 }}>
                <button
                  className="bg-gradient-to-l from-[#0D95CC] to-[#643DBD]
                      rounded-2xl outline-non text-white py-3 w-1/2 text-2xl"
                  type="submit"
                  onClick={handleFormSubmit}
                >
                  {content.Submit}
                </button>
              </div>
            </Row>
          </Col>
          <Col md={6} className="">
            <h1 className="text-3xl font-serif pt-8 text-center decoration-4 mb-5">
              {content.Contact}
            </h1>
            <Icon title={dataAll?.location}>
              <IoLocationSharp size={35} className="text-Main cursor-pointer" />
            </Icon>
            <Icon title={dataAll?.email}>
              <HiOutlineMail size={35} className="text-Main cursor-pointer" />
            </Icon>
            {dataAll?.number_one != 0 && (
              <Icon title={dataAll?.number_one}>
                <FaPhoneAlt size={30} className="text-Main cursor-pointer" />
              </Icon>
            )}
            {dataAll?.number_two != 0 && (
              <Icon title={dataAll?.number_two}>
                <FaPhoneAlt size={30} className="text-Main cursor-pointer" />
              </Icon>
            )}
            <p className="text-lg text-gray-600">
              {dataAll?.description_contact}
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactUsComponent;

import { Col, Container, Row } from "../../Tools/Grid-system";
import { ImageCard, Slider, Title } from "../../components/Index";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { fileUrl, useFETCH } from "../../Tools/APIs";
function Features() {
  const { data, isLoading } = useFETCH(
    `features?local=${localStorage.getItem("language")}`
  );
  return (
    <>
      <Slider />
      <Container>
        <Title />
        <h1 className="font-serif text-2xl text-center my-4">
          Unsere Features
        </h1>
        <Row justify="center">
          {isLoading ? (
            <>
              <Col sm={7} xs={5} lg={3}>
                <div className="loadingPro rounded-md  overflow-hidden w-full  h-[260px]">
                  <div className="h-full w-full"></div>
                </div>
              </Col>
              <Col sm={7} xs={5} lg={3}>
                <div className="loadingPro rounded-md  overflow-hidden w-full  h-[260px]">
                  <div className="h-full w-full"></div>
                </div>
              </Col>
              <Col sm={7} xs={5} lg={3}>
                <div className="loadingPro rounded-md  overflow-hidden w-full  h-[260px]">
                  <div className="h-full w-full"></div>
                </div>
              </Col>
              <Col sm={7} xs={5} lg={3}>
                <div className="loadingPro rounded-md  overflow-hidden w-full  h-[260px]">
                  <div className="h-full w-full"></div>
                </div>
              </Col>
            </>
          ) : (
            ""
          )}
          {data?.data.data.map((e) => (
            <Col lg={4} md={6} key={e.id}>
              <ImageCard
                className="max-sm:text-sm"
                title={e.title}
                text={e.description}
              >
                <Swiper
                  pagination={true}
                  modules={[Pagination]}
                  className="mySwiper"
                  style={{ direction: "ltr" }}
                >
                  {e.images?.map((e) => (
                    <SwiperSlide>
                      <img
                        src={fileUrl + e.image}
                        alt=""
                        className="w-full h-[280px] object-contain bg-gray-100"
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </ImageCard>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
}
export default Features;

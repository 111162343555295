import { Card } from "../../components/Index";
import { useFETCH } from "../../Tools/APIs";
import { Col, Container, Row } from "../../Tools/Grid-system";
function SectionTwo() {
  const { data, isLoading } = useFETCH(
    `services?local=${localStorage.getItem("language")}`
  );
  return (
    <Container>
      <Row className="justify-center">
        {isLoading ? (
          <>
            <Col lg={4} md={6}>
              <div className="animate-pulse bg-slate-300 rounded-md overflow-hidden w-full h-[260px]">
                <div className="h-full w-full"></div>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="animate-pulse bg-slate-300 rounded-md overflow-hidden w-full h-[260px]">
                <div className="h-full w-full"></div>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="animate-pulse bg-slate-300 rounded-md overflow-hidden w-full h-[260px]">
                <div className="h-full w-full"></div>
              </div>
            </Col>
          </>
        ) : (
          ""
        )}
        {data?.data.data.map((e) => (
          <Col lg={4} md={6}>
            <Card id={e.id} title={e.title} text={e.description} />
          </Col>
        ))}
      </Row>
    </Container>
  );
}
export default SectionTwo;
